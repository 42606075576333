<template>
<div>
  <vs-button color="success" size="small" v-if="file != 'null' && file">Download</vs-button>
  <vs-button color="primary" size="small" v-else>Upload</vs-button>
</div>
</template>

<script>
export default {
  name: "CellRendererFile",
  computed: {
    file () {
      return this.params.data.file;
    }
  }
}
</script>

<style scoped>

</style>
